import React, { Component, ReactElement } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable, reaction, IReactionDisposer } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import CreateStore from '../../../../../../stores/create.store'
import { Content, Action } from '../../../../../../models/Event.model'
import { eventTypeOptions, EventTypesEnum } from '../../../../../../utils/constants/event-types'
import ActionComponent from '../action.component'
import InputComponent from '../../../../../../utils/components/input-component'
import { FormComponent } from './form.component'
import { CheckboxesComponent } from './checkboxes.component'

const MAX_ACTIONS = 3

interface ContentComponentProps {
    content: Content
    fullPath: string
    createStore?: CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore
}))
@observer
class ContentComponent extends Component<ContentComponentProps, object> {
    @observable eventTypeIdx: number = EventTypesEnum.WithActions
    private fullPath: string
    private actionsPath: string

    @observable actions?: Action[]
    actionsUpdateReaction?: IReactionDisposer

    constructor(props: ContentComponentProps) {
        super(props)
        const { fullPath, content } = props

        this.eventTypeIdx = content.type
        this.fullPath = `${fullPath}/content`
        this.actionsPath = `${this.fullPath}/actions`

        this.actions = props.content.actions

        this.actionsUpdateReaction = reaction(
            () => props.content.actions,
            (actions) => {
                this.actions = actions

                // if (this.actions && this.actions.length) {
                //   this.props.createStore!.setProperty(`${this.fullPath}/type`, EventTypesEnum.WithActions)
                // } else {
                //   if (this.eventTypeIdx === EventTypesEnum.Form) {
                //     this.props.createStore!.setProperty(`${this.fullPath}/type`, EventTypesEnum.Form)
                //   } else {
                //     this.props.createStore!.setProperty(`${this.fullPath}/type`, EventTypesEnum.Tip)
                //   }
                // }
            }
        )
    }

    @action onEventTypeSelected = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.eventTypeIdx = parseInt(event.target.value)

        if (this.eventTypeIdx === EventTypesEnum.WithActions && !(this.props.content.actions && this.props.content.actions.length)) {
            this.props.createStore!.setProperty(this.actionsPath, [new Action()])
        } else if (this.eventTypeIdx === EventTypesEnum.Tip) {
            this.props.createStore!.setProperty(this.actionsPath, [])
        } else if (this.eventTypeIdx === EventTypesEnum.Form) {
            this.props.createStore!.setProperty(this.actionsPath, [])
        } else if (this.eventTypeIdx === EventTypesEnum.Checkbox) {
            this.props.createStore!.setProperty(this.actionsPath, [])
        }

        const actionTypePath = `${this.fullPath}/type`
        this.props.createStore!.setProperty(actionTypePath, this.eventTypeIdx)
    }

    // Added this to support dragging
    componentWillUpdate(props: ContentComponentProps) {
        this.actions = props.content.actions
        this.eventTypeIdx = props.content.type
    }

    addAction = () => {
        if (!this.actions) {
            this.props.createStore!.setProperty(this.actionsPath, [new Action()])
        } else {
            this.props.createStore!.addProperty(this.actionsPath, new Action())
        }
    }

    removeAction = (idx: number) => {
        this.props.createStore!.removePropertyFromArray(this.actionsPath, idx)
    }

    render() {
        const { content } = this.props

        return (
            <div className={'event-box-border'}>
                <label className={'event-box-label'}>Content</label>
                <TextField
                    id="type-option"
                    select
                    label="Event Type"
                    SelectProps={{}}
                    style={{ minWidth: 80 }}
                    margin="normal"
                    value={content.type || this.eventTypeIdx}
                    onChange={this.onEventTypeSelected}
                >
                    {eventTypeOptions.map((option: any, idx: number) => (
                        <MenuItem key={`o${idx}`} value={idx}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>

                {this.eventTypeIdx === EventTypesEnum.Form ? (
                    <FormComponent fullPath={this.fullPath} content={content} />
                ) : (
                    <React.Fragment>
                        <InputComponent
                            id="message"
                            label="Message"
                            multiline
                            variant="outlined"
                            fullWidth
                            minRows="3"
                            required
                            margin="normal"
                            fullPath={`${this.fullPath}/message`}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        {/* <InputComponent
          id='comment'
          label='comment'
          multiline
          variant='outlined'
          fullWidth
          margin='normal'
          fullPath={`${this.fullPath}/comment`}
          InputLabelProps={{
              shrink: true,
          }}
          /> */}

                        {this.eventTypeIdx === EventTypesEnum.Checkbox ? (
                            <CheckboxesComponent fullPath={this.fullPath} content={content} />
                        ) : (
                            <ActionsView
                                actions={this.actions}
                                fullPath={this.fullPath}
                                addAction={this.addAction}
                                removeAction={this.removeAction}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        )
    }
}

interface ActionsViewProps {
    actions?: Action[]
    fullPath: string
    addAction: (event: any) => void
    removeAction: (event: any) => void
}
const ActionsView = observer((props: ActionsViewProps) => {
    const { actions, fullPath, addAction, removeAction } = props

    let elements: ReactElement[] = []
    if (actions && actions.length) {
        elements = actions!.map((action: Action, idx: number) => (
            <ActionComponent action={action} fullPath={fullPath} dataKey={idx} key={`a-${idx}`} removeAction={removeAction.bind({}, idx)} />
        ))
    }

    if (!actions || actions!.length < MAX_ACTIONS) {
        elements.push(
            <Button fullWidth onClick={addAction} key={`b`}>
                Add Action
            </Button>
        )
    }

    return <div>{elements}</div>
})

export default ContentComponent
