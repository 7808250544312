enum EventTypesEnum {
    WithActions,
    Tip,
    Form,
    Checkbox
}

const EventTypes = {
    [EventTypesEnum[0]]: { label: 'With Actions', idx: 0 },
    [EventTypesEnum[1]]: { label: 'Tip', idx: 1 },
    [EventTypesEnum[2]]: { label: 'Form', idx: 2 },
    [EventTypesEnum[3]]: { label: 'Checkboxes', idx: 3 }
}

const eventTypeOptions = Object.values(EventTypes)

export { eventTypeOptions, EventTypes, EventTypesEnum }
