import Event, {
    Content,
    Appearance,
    Availability,
    Action,
    Dependency,
    Settings,
    EventFormItem,
    EventRoles,
    CheckboxItem,
    AvailabilityAfterEvent
} from '../models/Event.model'
import { PlanDeadline } from '../models/Plan-deadline.model'

const parseEvent = (eventData: any): Event => {
    let event

    // Settings backwards compatibility
    let settings: Settings
    if (eventData.settings) {
        settings = parseSettings(eventData.settings)
    } else {
        const { shouldShowOnPreview } = eventData
        settings = parseSettings({ shouldShowOnPreview })
    }

    if (!eventData.dependencies) {
        const dependencies = [
            {
                availability: eventData.availability,
                appearance: eventData.appearance
            }
        ]

        event = new Event(
            eventData.title,
            eventData._id,
            parseDependencies(dependencies),
            parseContent(eventData.content),
            settings,
            parseDeadline(eventData.deadline),
            parseEventRoles(eventData.roles)
        )
    } else {
        event = new Event(
            eventData.title,
            eventData._id,
            parseDependencies(eventData.dependencies),
            parseContent(eventData.content),
            settings,
            parseDeadline(eventData.deadline),
            parseEventRoles(eventData.roles)
        )
    }

    return event
}

const parseSettings = (settings: any): Settings => {
    return new Settings(settings.shouldShowOnPreview, settings.shouldDisplayOverWeb)
}

const parseContent = (contentData: any): Content => {
    const actions: [Action] = [] as any as [Action]
    const form: [EventFormItem] = [] as any as [EventFormItem]
    const checkboxes: [CheckboxItem] = [] as any as [CheckboxItem]
    if (contentData.actions) {
        for (const actionData of contentData.actions) {
            actions.push(parseAction(actionData))
        }
    }

    if (contentData.form) {
        for (const formItemData of contentData.form) {
            form.push(parseFormItem(formItemData))
        }
    }

    if (contentData.checkboxes) {
        for (const checkboxItemData of contentData.checkboxes) {
            checkboxes.push(parseCheckboxItem(checkboxItemData))
        }
    }

    const content = new Content(contentData.type, contentData.message, actions, contentData.comment, form, checkboxes)

    return content
}

const parseAction = (actionData: any): Action => {
    const action = new Action(
        actionData.id,
        actionData.label,
        actionData.type,
        actionData.link,
        actionData.call,
        actionData.repeatIn,
        actionData.remindIn,
        actionData.end,
        actionData.eventFiles,
        actionData.note
    )

    return action
}

const parseFormItem = (formItemData: any): EventFormItem => {
    const action = new EventFormItem(formItemData.key, formItemData.type, formItemData.isMultiLine, formItemData.required)

    return action
}

const parseCheckboxItem = (checkboxItemData: any): CheckboxItem => {
    const checkboxItem = new CheckboxItem(checkboxItemData.id, checkboxItemData.label, checkboxItemData.value)

    return checkboxItem
}

// const parseDependency = (dependencyData: any): Dependency => {
//     const dependency = new Dependency(parseAvailability(dependencyData.availability), parseAppearance(dependencyData.appearance))

//     return dependency
// }

const parseAppearance = (appearanceData: any): Appearance => {
    const appearance = new Appearance(appearanceData.time, appearanceData.location)

    return appearance
}

const parseAvailabilityAfterEvent = (availabilityData: any): AvailabilityAfterEvent[] => {
    const isOldFormat =
        availabilityData.afterEvents && availabilityData.afterEvents.length > 0 && availabilityData.afterEvents[0].endWith === undefined
    if (isOldFormat) {
        const availabilityAfterEvents = [] as AvailabilityAfterEvent[]
        // For backwards compatibility
        availabilityData.afterEvents.forEach((afterEvent: any) => {
            availabilityAfterEvents.push({
                eventId: afterEvent,
                endWith:
                    availabilityData.eventEndIdx === 5 || availabilityData.eventEndIdx === undefined // 5 is the default value for 'any' type of end, see backoffice at src/utils/components/event-selector.tsx
                        ? { type: 'any', value: undefined }
                        : { type: 'action', value: availabilityData.eventEndIdx }
            })
        })

        return availabilityAfterEvents
    }

    return availabilityData.afterEvents
}

const parseAvailability = (availabilityData: any): Availability => {
    const afterEvents = parseAvailabilityAfterEvent(availabilityData)

    const availability = new Availability(availabilityData.afterTime, afterEvents)

    return availability
}

const parseDependencies = (dependenciesData: any): [Dependency] => {
    const dependencies = [] as any as [Dependency]

    for (const dependencyData of dependenciesData) {
        dependencies.push(
            new Dependency(parseAvailability((dependencyData as any).availability), parseAppearance((dependencyData as any).appearance))
        )
    }

    return dependencies
}

const parseDeadline = (data: any): PlanDeadline | undefined => {
    return data ? new PlanDeadline(data.type, data.value) : undefined
}

const parseEventRoles = (data: any): EventRoles | undefined => {
    return data ? new EventRoles(data.executors, data.viewers, data.notified) : undefined
}

export { parseEvent }
