import React, { useState } from 'react'
import {
    Chip, Input,
    IconButton,
    makeStyles, MenuItem, Select, Theme,
    createStyles, useTheme, InputLabel, FormControl, InputAdornment
} from '@material-ui/core'
import { useTranslation } from 'react-i18next';

import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add'
import CancelIcon from '@material-ui/icons/Cancel'
import SaveIcon from '@material-ui/icons/Save'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/root.store';
import { Role } from '../../../../models/settings/Organization.model';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        margin: {
            margin: theme.spacing(1),
        },

    }),
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, selectedRoles: string[], theme: Theme) {
    return {
        fontWeight:
            selectedRoles.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface Props {
    onSelectedRolesChanged(roles: Role[]): void
}
export const RolesPicker = observer((props: Props) => {
    const classes = useStyles();
    const theme = useTheme();

    const { t } = useTranslation()

    const store = useStore('organizationStore')
    const organization = store.organization

    const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

    const [addingNew, setAddingNew] = useState(false)
    const [newRole, setNewRole] = useState('')

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedRoles(event.target.value as string[]);
        props.onSelectedRolesChanged(organization!.roles.filter(r => selectedRoles.indexOf(r.id) !== -1))
    };

    const addNewRole = () => {
        store.addRole(newRole).finally(() => {
            setNewRole('')
            setAddingNew(false)
        })
    }

    if (!organization) return <></>

    return <div style={{ display: 'flex', flexDirection: 'row', width: '45%', margin: '0 8px' }}>
        {addingNew ?
            <>
                <FormControl className={classes.margin}>
                    <InputLabel htmlFor="input-with-icon-adornment">{t('New Role')}</InputLabel>
                    <Input
                        id="new-role-input"
                        startAdornment={
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        }

                        onChange={(event) => setNewRole(event.target.value)}
                    />
                </FormControl>

                <IconButton aria-label="expand row" size="small" onClick={() => setAddingNew(false)}>
                    <CancelIcon />
                </IconButton>
                <IconButton disabled={!newRole} aria-label="expand row" size="small" onClick={addNewRole}>
                    <SaveIcon />
                </IconButton>
            </>
            :
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">{t('roles')}</InputLabel>
                    <Select
                        labelId="roles-select-label"
                        id="roles-select"
                        multiple
                        value={selectedRoles}
                        onChange={handleChange}
                        input={<Input id="select-multiple-chip"/>}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {(selected as string[]).map((value) => (
                                    <Chip key={value} label={organization.roles.find(r => r.id === value)!.text} className={classes.chip} />
                                ))}
                            </div>
                        )}
                        MenuProps={MenuProps}
                    >
                        {organization.roles.map((role) => (
                            <MenuItem key={role.id} value={role.id} style={getStyles(role.id, selectedRoles, theme)}>
                                {role.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <IconButton aria-label="expand row" size="small" onClick={() => setAddingNew(true)}>
                    <AddIcon />
                </IconButton>
            </>
        }
    </div>
})
