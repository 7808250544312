import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import TextField from '@material-ui/core/TextField'

import Input from '@material-ui/core/Input'

import Select from '@material-ui/core/Select'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import PathEvent, { AvailabilityAfterEvent, DEFAULT_EVENT_END } from '../../models/Event.model'
import { getPathIdx, getEventIdx } from '../component-path'

import { EventTypesEnum } from '../constants/event-types'
import { ActionsEnum } from '../constants/action-types'
import { useStore } from '../../stores/root.store'
import Event from '../../models/Event.model'

interface EventSelectorComponentProps {
    eventFullPath: string
    avoidCurrentEvent?: boolean
}

const EventSelectorComponent = observer(({ eventFullPath, avoidCurrentEvent }: EventSelectorComponentProps) => {
    const createStore = useStore('createStore')
    const [selectedEventsOptions, setSelectedEventsOptions] = useState<string[]>([])
    const [selectedEvents, setSelectedEvents] = useState<AvailabilityAfterEvent[]>([])
    const [eventOptions, setEventOptions] = useState<Event[]>([])

    useEffect(() => {
        const pathIdx = getPathIdx(eventFullPath)
        let eventOptions = createStore.paths.map((p) => p.events).flat()

        if (avoidCurrentEvent) {
            const eventID = createStore.paths[pathIdx].events[getEventIdx(eventFullPath)]._id
            eventOptions = eventOptions.filter((e) => e._id !== eventID)
        }

        setEventOptions(eventOptions)

        const initialSelectedEvents = createStore.getPropertyByPath<AvailabilityAfterEvent[]>(eventFullPath) || []
        const initialSelectedOptions = initialSelectedEvents.map((e) => e.eventId)

        const eventOptionsSet = new Set(eventOptions.map((e) => e._id))
        const filteredOptions = initialSelectedOptions.filter((e) => eventOptionsSet.has(e))

        setSelectedEventsOptions(filteredOptions)
        setSelectedEvents(initialSelectedEvents)

        if (filteredOptions.length === 0) {
            createStore.setProperty(eventFullPath, filteredOptions)
        }
    }, [avoidCurrentEvent, createStore, eventFullPath])

    const handleChange = (selectEvent: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const selectedOptions = selectEvent.target.value as string[]

        setSelectedEventsOptions(selectedOptions)

        const updatedEvents = selectedOptions.map((v) => ({ eventId: v, endWith: { type: 'any', value: undefined } } as AvailabilityAfterEvent))
        setSelectedEvents(updatedEvents)
        createStore.setProperty(eventFullPath, updatedEvents)
    }

    return (
        <span>
            <Select
                multiple
                value={selectedEventsOptions}
                onChange={handleChange}
                input={<Input id="select-multiple-chip" name="events" />}
                style={{ marginTop: '32px', marginBottom: '8px', maxWidth: '400px' }}
                renderValue={(selectedEventIds: any) =>
                    selectedEventIds
                        .map((sEvent: string) => {
                            const event = eventOptions.find((event) => event._id === sEvent)
                            return event ? event.title : ''
                        })
                        .join(', ')
                }
            >
                {eventOptions.map((pathEvent, idx) => (
                    <MenuItem key={`evt${idx}`} value={pathEvent._id}>
                        <Checkbox checked={selectedEventsOptions.includes(pathEvent._id)} />
                        <ListItemText primary={pathEvent.title} />
                    </MenuItem>
                ))}
            </Select>

            {selectedEvents.map((se, index) => (
                <EndView key={index} fullPath={`${eventFullPath}/${index}`} pathEvent={eventOptions.find((e) => e._id === se.eventId)!} />
            ))}
        </span>
    )
})

const EndView = observer((props: { pathEvent: PathEvent; fullPath: string }) => {
    const { pathEvent, fullPath } = props

    if (!pathEvent) return <>No Event</>

    return (
        <div className="row" style={{ paddingLeft: 12, margin: 12 }}>
            <Typography style={{ marginRight: 8 }}>{pathEvent.title} Ends With</Typography>
            {pathEvent.content.type === EventTypesEnum.WithActions ? (
                <EndWithActionsView pathEvent={pathEvent} fullPath={fullPath} />
            ) : pathEvent.content.type === EventTypesEnum.Checkbox ? (
                <EndWithCheckboxesView pathEvent={pathEvent} fullPath={fullPath} />
            ) : (
                <Typography>Any</Typography>
            )}
        </div>
    )
})

// const EndWithActionView = observer((props: { pathEvent: PathEvent; fullPath: string; after: AvailabilityAfterEvent }) => {
//     const { pathEvent, fullPath } = props

//     let options: [{ label: string; key: number }] | undefined
//     if (pathEvent && pathEvent.content.type === EventTypesEnum.WithActions && pathEvent.content.actions) {
//         options = pathEvent.content.actions
//             .filter((action: Action) => action.type === ActionsEnum.EndWith || action.type === ActionsEnum.End)
//             .map((action: Action, idx: number) => ({ label: action.end || action.label, key: idx })) as [{ label: string; key: number }]

//         // TODO: fix this 5 unreadable issue
//         if (options.length) options.unshift({ label: 'Default (any end)', key: DEFAULT_EVENT_END })
//     }

//     if (!(options && options.length)) {
//         return <span>No Options</span>
//     } else {
//         const path = `${fullPath.split('/afterEvents')[0]}/eventEndIdx`
//         return (
//             <span>
//                 <SelectComponent options={options} fullPath={path} defaultOptionIdx={0} />
//             </span>
//         )
//     }
// })

const EndWithActionsView = observer((props: { pathEvent: PathEvent; fullPath: string }) => {
    const { pathEvent, fullPath } = props
    const createStore = useStore('createStore')

    const after = createStore.getPropertyByPath<AvailabilityAfterEvent>(fullPath)

    const onTypeChange = (selectEvent: React.ChangeEvent<{ name?: string; value: string }>) => {
        createStore.setProperty(`${fullPath}/endWith/type`, selectEvent.target.value)
    }

    const onActionChange = (selectEvent: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        createStore.setProperty(`${fullPath}/endWith/value`, selectEvent.target.value)
    }

    // const value = typeof after.endWith.value === 'number' ? [after.endWith.value] : after.endWith.value ? (after.endWith.value as string[]) : []

    const getActionIdBc = (index: number): string => {
        const action = pathEvent.content.actions!.filter((action) => action.type === ActionsEnum.EndWith)[index]
        return action?.id
    }

    return (
        <span>
            <TextField key="type" select required value={after.endWith.type} onChange={onTypeChange}>
                {['any', 'action'].map((option, idx) => (
                    <MenuItem key={`opt-${idx}`} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            {after.endWith.type === 'action' ? (
                <Select
                    value={after.endWith.value}
                    onChange={onActionChange}
                    input={<Input id="select-multiple-chip" name="action" />}
                    renderValue={(s: any) => pathEvent.content.actions?.find((c) => s === c.id)?.label}
                >
                    {(pathEvent.content.actions || [])
                        .filter((a) => a.type !== ActionsEnum.Remind && a.type !== ActionsEnum.Repeat)
                        .map((a, idx: number) => (
                            <MenuItem key={`evt${idx}`} value={a.id}>
                                <Checkbox
                                    checked={typeof after.endWith.value === 'number' ? getActionIdBc(idx) === a.id : after.endWith.value === a.id}
                                />
                                <ListItemText primary={a.label} />
                            </MenuItem>
                        ))}
                </Select>
            ) : null}
        </span>
    )
})

const EndWithCheckboxesView = observer((props: { pathEvent: PathEvent; fullPath: string }) => {
    const { pathEvent, fullPath } = props
    const createStore = useStore('createStore')
    const after = createStore.getPropertyByPath<AvailabilityAfterEvent>(fullPath)

    const onTypeChange = (selectEvent: React.ChangeEvent<{ name?: string; value: string }>) => {
        createStore.setProperty(`${fullPath}/endWith/type`, selectEvent.target.value)
    }

    const onCheckboxesChange = (selectEvent: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        createStore.setProperty(`${fullPath}/endWith/value`, selectEvent.target.value)
    }

    return (
        <span key="end-with-checkboxes-view">
            <TextField key="type" select required value={after.endWith.type} onChange={onTypeChange}>
                {['any', 'checkboxes'].map((option, idx) => (
                    <MenuItem key={`opt-${idx}`} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>

            {after.endWith.type === 'checkboxes' ? (
                <Select
                    multiple
                    value={after.endWith.value || []}
                    onChange={onCheckboxesChange}
                    input={<Input id="select-multiple-chip" name="checkboxes" />}
                    renderValue={(selected: any) =>
                        selected.map((s: string) => pathEvent.content.checkboxes?.find((c) => s === c.id)?.label).join(', ')
                    }
                >
                    {pathEvent.content.checkboxes?.map((c, idx: number) => (
                        <MenuItem key={`evt${idx}`} value={c.id}>
                            <Checkbox checked={((after.endWith.value as string[]) || []).findIndex((cId) => cId === c.id) > -1} />
                            <ListItemText primary={c.label} />
                        </MenuItem>
                    ))}
                </Select>
            ) : null}
        </span>
    )
})

export default EventSelectorComponent
