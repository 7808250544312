import React from 'react'

import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'

import { CheckboxItem } from '../../../../../../models/Event.model'
import CreateStore from '../../../../../../stores/create.store'
import InputComponent from '../../../../../../utils/components/input-component'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formItemContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    })
)

interface Props {
    fullPath: string
    checkboxItem: CheckboxItem
    createStore?: CreateStore
    dataKey: number
    removeItem: () => void
}
const CheckboxItemComponent = inject('createStore')(
    observer((props: Props) => {
        const { fullPath, checkboxItem, createStore, removeItem, dataKey } = props
        const classes = useStyles()
        const { t } = useTranslation('event')

        return (
            <div className={classes.formItemContainer + ' event-box-border'}>
                {dataKey > 0 && <DeleteIcon className={'remove-icon'} onClick={removeItem} />}
                <div className={classes.row}>
                    <InputComponent
                        id="key"
                        label={t('label')}
                        variant="outlined"
                        required
                        margin="normal"
                        fullPath={`${fullPath}/label`}
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        style={{ marginRight: 10 }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkboxItem.value}
                                onChange={(e, checked) => createStore!.setProperty(`${fullPath}/value`, checked)}
                            />
                        }
                        label={t('defaultValue')}
                    />
                </div>
            </div>
        )
    })
)

export { CheckboxItemComponent }
