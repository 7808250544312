import React, { Component, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Typography from '@material-ui/core/Typography'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { Menu, MenuItem, IconButton, Dialog, DialogTitle, ListItem, List, ListItemText } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'
import ShowIcon from '@material-ui/icons/Visibility'

import PathEvent, { Settings } from '../../../../../models/Event.model'
import CreateStore from '../../../../../stores/create.store'

import ContentComponent from './content/content.component'
import InputComponent from '../../../../../utils/components/input-component'
import DependenciesComponent from './dependencies.component'
import Path from '../../../../../models/Path.model'
import './event.style.scss'
import { DeadlinePicker } from '../Deadline'
import { PlanTypeEnum } from '../../../../../utils/constants/plan-type'
import { EventRolesView } from './event-roles'
import { useStore } from '../../../../../stores/root.store'

interface EventComponentProps {
    fullPath: string
    event: PathEvent
    dataKey: number
    removeEvent: (any: any) => void
}
const EventComponent: React.FC<EventComponentProps> = observer((props) => {
    const { fullPath, dataKey, event, removeEvent } = props
    const fullPathRef = `${fullPath}/events/${dataKey}`
    const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null)
    const [isPathsDialogOpen, setIsPathsDialogOpen] = useState(false)
    const [settings, setSettings] = useState(event.settings)

    const createStore = useStore('createStore')
    // Full path = ["paths", pathIdx, "events", EventIdx]
    const pathIdx = parseInt(fullPathRef.split('/')[1])
    const pathsOptions = createStore!.paths.filter((p, idx) => idx !== pathIdx)

    const onMenuClick = (event: any) => {
        setAnchorElement(event.target)
    }

    const handleMenuClose = () => {
        setAnchorElement(null)
    }

    const onSettingsChanged = (prop: keyof Settings, checked: boolean) => {
        createStore!.setProperty(`${fullPathRef}/${prop}`, checked)
        setSettings((prev) => ({ ...prev, [prop]: checked }))
    }

    const duplicate = () => {
        createStore!.duplicateEvent(parseInt(fullPathRef.split('/')[1], 10), event)
        handleMenuClose()
    }

    const openPathsDialog = () => {
        handleMenuClose()
        setIsPathsDialogOpen(true)
    }

    const displayEvent = () => {
        createStore!.setDisplayedEvent(event)
    }

    const handleDialogClose = (path?: Path) => {
        setIsPathsDialogOpen(false)

        if (path) {
            const newPathIdx = createStore!.paths.findIndex((p) => p._id === path._id)
            createStore!.switchPath(pathIdx, newPathIdx, event)
        }
    }

    const onExpandChanged = (_: React.ChangeEvent<{}>, expanded: boolean) => {
        if (expanded) {
            createStore.onPropsChanged(fullPathRef)
        }
    }

    if (!event) {
        return null
    }

    return (
        <Accordion
            defaultExpanded={!event.title}
            className="eventPanel"
            classes={{ expanded: 'panelExpanded' }}
            event-id={event._id}
            onChange={onExpandChanged}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">
                    Event {dataKey + 1} {event.title && `- ${event.title}`}
                </Typography>
                {!!dataKey && <DeleteIcon className="remove-icon" onClick={removeEvent} />}
            </AccordionSummary>

            <AccordionDetails className="main-container">
                {/* // TODO: use only single PathsDialog */}
                <PathsDialog paths={pathsOptions} isOpen={isPathsDialogOpen} onClose={handleDialogClose} />

                <span className="row">
                    <InputComponent
                        id="event_title"
                        className="event-title-input"
                        label="Title"
                        required
                        fullPath={`${fullPathRef}/title`}
                        style={{ margin: '0 8px' }}
                        placeholder="title"
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                    />

                    <DeadlinePicker deadlinePath={`${fullPathRef}/deadline`} />

                    <Button size="large" onClick={onMenuClick}>
                        Options
                    </Button>

                    <Menu id="options-menu" anchorEl={anchorElement} open={Boolean(anchorElement)} onClose={handleMenuClose}>
                        <MenuItem onClick={duplicate}>Duplicate Event</MenuItem>
                        <MenuItem onClick={openPathsDialog} disabled={!pathsOptions.length}>
                            Switch Path
                        </MenuItem>
                    </Menu>

                    <IconButton color="primary" onClick={displayEvent}>
                        <ShowIcon />
                    </IconButton>
                </span>

                {createStore!.planType === PlanTypeEnum.MultiUsers && <EventRolesView path={`${fullPathRef}/roles`} />}

                <EventSettings settings={settings} onCheck={onSettingsChanged} />

                <ContentComponent content={event.content} fullPath={fullPathRef} />
                <DependenciesComponent dependencies={event.dependencies} fullPath={fullPathRef} />
            </AccordionDetails>
        </Accordion>
    )
})

const EventSettings = observer((props: { settings: Settings; onCheck: (prop: keyof Settings, checked: boolean) => void }) => {
    const { settings, onCheck } = props
    return (
        <div className={'event-box-border'}>
            <label className={'event-box-label'}>Event Settings</label>
            <FormControlLabel
                control={<Checkbox checked={settings.shouldShowOnPreview} onChange={(e, checked) => onCheck('shouldShowOnPreview', checked)} />}
                label={'Should show on preview?'}
            />
            {/* <FormControlLabel
      control={
        <Checkbox checked={settings.shouldDisplayOverWeb}
          onChange={(e, checked) => onCheck('shouldDisplayOverWeb', checked)} />
      }
      label={'Should show over web page?'} /> */}
        </div>
    )
})

interface PathsDialogProps {
    onClose: (path?: Path) => any
    isOpen: boolean
    paths: Path[]
}
const PathsDialog = observer((props: PathsDialogProps) => {
    const { onClose, isOpen, paths } = props

    const handleClose = () => {
        onClose()
    }

    const handleListItemClick = (value: Path) => {
        onClose(value)
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
            <DialogTitle id="simple-dialog-title">Move to path:</DialogTitle>
            <List>
                {paths.map((p) => (
                    <ListItem button onClick={() => handleListItemClick(p)} key={p._id}>
                        <ListItemText primary={p.title} />
                    </ListItem>
                ))}
            </List>
        </Dialog>
    )
})

export default EventComponent
