import React, { Component } from 'react'
import { reaction } from 'mobx'
import { inject, observer } from 'mobx-react'

import { RouteComponentProps } from 'react-router'

import Grid from '@material-ui/core/Grid'
import { Card, FormControl, InputLabel, Checkbox, MenuItem, Select, Input, TextField, ListItemText, Typography } from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import MainStore from '../../../stores/main.store'
import SettingsStore from '../../../stores/settings.store'

import Organization, { PlansGroup } from '../../../models/settings/Organization.model'
import Plan from '../../../models/Plan.model'
import './organization.style.scss'
import PlanPreview from '../../../models/Plan-preview.model'

const MAX_GROUPS = 3

interface OrganizationScreenProps extends RouteComponentProps {
    mainStore?: MainStore
    settingsStore?: SettingsStore
}

@inject((stores) => ({
    mainStore: (stores as any).mainStore as MainStore,
    settingsStore: (stores as any).settingsStore as SettingsStore
}))
@observer
class OrganizationScreen extends Component<OrganizationScreenProps, any> {
    constructor(props: OrganizationScreenProps) {
        super(props)

        const { match, settingsStore } = this.props
        const { id } = match!.params as any

        if (id) {
            try {
                settingsStore!.setSelectedOrganization(id)
            } catch (err) {
                this.props.history.push('')
            }
        } else {
            this.props.history.push('')
        }
    }

    componentDidMount = () => {}

    componentWillUnmount = () => {}

    componentDidUpdate(prevProps: OrganizationScreenProps, prevState: {}) {}

    onAddGroupClicked = () => {
        this.props.settingsStore!.addNewGroup(this.props.settingsStore!.selectedOrganization!)
    }

    onNameChange = (group: PlansGroup, name: string) => {
        this.props.settingsStore!.updateGroupName(group, name)
    }

    onGroupPlansChange = (group: PlansGroup, planIds: string[]) => {
        this.props.settingsStore!.updatePlanGroups(group, planIds)
    }

    onSaveClicked = () => {
        this.props.settingsStore!.updateSelectedOrganization()
    }

    render() {
        const { selectedOrganization } = this.props.settingsStore!
        const { plans } = this.props.mainStore!

        if (!selectedOrganization) {
            return <span></span>
        }

        return (
            <Grid container alignItems={'center'} direction={'column'} className="organization-screen">
                <Grid item xs={12} sm={6} className="content">
                    <Card className="logo-container">
                        <img src={selectedOrganization!.coverLogo} alt="" className="organization-logo" />
                    </Card>

                    <div className="groups-container">
                        <span className="groups-label">Groups</span>
                        <div className="groups">
                            {selectedOrganization.groups &&
                                selectedOrganization.groups.map((g, idx) => (
                                    <GroupView
                                        group={g}
                                        plans={plans}
                                        key={idx}
                                        onPlansChange={this.onGroupPlansChange.bind(this, g)}
                                        onNameChange={this.onNameChange.bind(this, g)}
                                    />
                                ))}

                            {(!selectedOrganization.groups || selectedOrganization.groups.length < MAX_GROUPS) && (
                                <Card className="group-card add-group-card" onClick={this.onAddGroupClicked.bind(this)}>
                                    <AddIcon />
                                </Card>
                            )}
                        </div>
                    </div>

                    <Card className="save-container" onClick={this.onSaveClicked}>
                        <Typography variant="h5">Save</Typography>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

interface GroupViewProps {
    group: PlansGroup
    plans: PlanPreview[]
    onNameChange: (text: string) => any
    onPlansChange: (planIds: string[]) => any
}
const GroupView = observer((props: GroupViewProps) => {
    const { group, plans, onNameChange, onPlansChange } = props

    const groupPlansSet = new Set(group.planIds)
    const chosenPlans = plans.filter(({ _id }) => groupPlansSet.has(_id))

    const onPlanClicked = (node: any, object: any) => {
        const key = node.currentTarget.dataset.id
        let planIds
        if (groupPlansSet.has(key)) {
            planIds = group.planIds.filter((pId) => pId !== key)
        } else {
            planIds = group.planIds
            planIds.push(key)
        }
        onPlansChange(planIds)
    }

    return (
        <Card className="group-card">
            <TextField onChange={(event) => onNameChange(event.target.value)} label="Name" type="text" value={group.name} />

            <FormControl>
                <InputLabel id="demo-mutiple-chip-label">Plans</InputLabel>
                <Select
                    id="demo-mutiple-chip"
                    multiple
                    value={chosenPlans}
                    onChange={onPlanClicked}
                    input={<Input id="select-multiple-chip" name="plans" />}
                    renderValue={(selected) => `${(selected as Plan[]).length} ${(selected as Plan[]).map((p) => p.title).join(',')}`}
                >
                    {plans.map(({ _id, title: name }, index: number) => (
                        <MenuItem key={index} value={name} data-id={_id}>
                            <Checkbox checked={groupPlansSet.has(_id)} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Card>
    )
})

export default OrganizationScreen
