import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import MainStore from '../../../stores/main.store'
import CreateStore from '../../../stores/create.store'
import SettingsStore from '../../../stores/settings.store'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import { RouteProps } from 'react-router'

import './SettingsScreen.style.scss'
import SettingsCard from './components/settings-card.component';
import { CircularProgress } from '@material-ui/core';

interface SettingsScreenProps extends RouteProps {
  mainStore: MainStore
  createStore: CreateStore
  settingsStore: SettingsStore
}

@inject((stores) => ({
  mainStore: (stores as any).mainStore as MainStore,
  createStore: (stores as any).createStore as CreateStore,
  settingsStore: (stores as any).settingsStore as SettingsStore,
}))
@observer
class SettingsScreen extends Component<any, object> {

  componentWillMount = async () => {
    // this.props.settingsStore.init()
  }

  componentDidMount = () => {
    // this.props.mainStore.init()
  }

  componentWillUnmount = () => {
    // this.props.mainStore.reset()
  }

  render() {
    const { isLoading } = this.props.settingsStore
    const { snackMessage } = this.props.createStore


    if (isLoading) {
      return <div className='loading'>
        <CircularProgress />
      </div>
    } else {
      return (

        <Grid container alignItems={'center'} direction={'column'} className='settings-screen'>
          {/* <Fab color="primary" aria-label="Add"  style={{left: '10px', position: 'absolute'}}>
          <AddIcon />
        </Fab> */}
          <Grid item xs={12} style={{ minWidth: '400px' }}>
            <Typography variant='h6'>
              Settings
          </Typography>
          </Grid>

          <SettingsCard history={this.props.history}>
          </SettingsCard>
        </Grid>
      )
    }
  }
}

export default SettingsScreen
