import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../../stores/root.store'
import { AddUserDialog } from './add-user.component'
import { observer } from 'mobx-react-lite'

export const OrganizationUsers = observer(() => {
    const { t } = useTranslation('organizationUsers')
    const store = useStore('organizationStore')
    const organization = store.organization!

    const [addingUser, setAdding] = useState(false)

    const onCancel = () => {
        store.setIsEditingUsers(false)
    }

    return (
        <Dialog onClose={onCancel} aria-labelledby="customized-dialog-title" open className="add-organization" onBackdropClick={onCancel}>
            <DialogTitle>{t('title')}</DialogTitle>

            <DialogContent className="dialog-content">
                {organization.users
                    .map((u) => store.organizationUsers.find((user) => user.id === u.userId))
                    .filter((u) => u !== undefined)
                    .map((u) => (
                        <div style={{ display: 'flex', flexDirection: 'row', width: '400px', justifyContent: 'space-between' }}>
                            <Typography>{u!.name}</Typography>
                            <Typography>{u!.email}</Typography>
                            <Typography>{u!.phone}</Typography>
                            {/* <Button>{t('edit')}</Button> */}
                        </div>
                    ))}
                {addingUser && <AddUserDialog onClose={() => setAdding(false)} onFinished={(a: any) => {}} />}
                <Button onClick={() => setAdding(true)}>{t('addUser')}</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    size="large"
                    autoFocus
                    onClick={() => {
                        onCancel()
                    }}
                >
                    {t('finish')}
                </Button>
            </DialogActions>
        </Dialog>
    )
})
