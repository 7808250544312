import React, { useCallback, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, LinearProgress } from '@material-ui/core'

import { Content, EventFormItem, Action, CheckboxItem } from '../../../../../../models/Event.model'
import CreateStore from '../../../../../../stores/create.store'
import InputComponent from '../../../../../../utils/components/input-component'
import { FromItemComponent } from './form-item.component'
import ActionComponent from '../action.component'
import { CheckboxItemComponent } from './checkboxItem.component'
import { ActionsEnum } from '../../../../../../utils/constants/action-types'

interface Props {
    content: Content
    fullPath: string
    createStore?: CreateStore
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContainer: {},
        childrenContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 0',
            borderLeft: 'solid 1px',
            marginLeft: '60px'
        }
    })
)

const CheckboxesComponent = inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore
}))(
    observer((props: Props) => {
        const { createStore, fullPath, content } = props
        const classes = useStyles()
        const { t } = useTranslation('event')

        const addCheckbox = useCallback(() => {
            if (!content.checkboxes) {
                createStore!.setProperty(`${fullPath}/checkboxes`, [new CheckboxItem()])
            } else {
                createStore!.addProperty(`${fullPath}/checkboxes`, new CheckboxItem())
            }
        }, [])

        const removeCheckboxItem = useCallback((idx: number) => {
            createStore!.removePropertyFromArray(`${fullPath}/checkboxes`, idx)
        }, [])

        useEffect(() => {
            if (!content.actions || !content.actions.length) {
                createStore!.setProperty(`${fullPath}/actions`, [new Action(), new Action(undefined, undefined, ActionsEnum.Remind)])
            }

            if (!content.checkboxes) {
                createStore!.setProperty(`${fullPath}/checkboxes`, [new CheckboxItem()])
            }
        })

        return (
            <div className={classes.formContainer + ' event-box-border'}>
                <label className={'event-box-label'}>Checkboxes</label>

                {!!content.checkboxes &&
                    content.checkboxes.map((checkboxItem, index) => (
                        <CheckboxItemComponent
                            key={index}
                            dataKey={index}
                            fullPath={`${fullPath}/checkboxes/${index}`}
                            checkboxItem={checkboxItem}
                            removeItem={() => removeCheckboxItem(index)}
                        />
                    ))}

                <Button fullWidth onClick={addCheckbox}>
                    {t('addCheckbox')}
                </Button>

                {!!content.actions && content.actions.length && (
                    <>
                        <InputComponent
                            id="submitLabel"
                            label="Submit Label"
                            name="submitLabel"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            fullPath={`${fullPath}/actions/0/label`}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <InputComponent
                            id="remindLabel"
                            name="remindLabel"
                            label="Remind Label"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            fullPath={`${fullPath}/actions/1/label`}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </>
                )}
            </div>
        )
    })
)

export { CheckboxesComponent }
