
import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { BasePositionModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import { EventEndPortModel } from './event-end-port/end-port.model'

export interface EventNodeModelOptions extends BasePositionModelOptions {
	name?: string;
	color?: string;
}

export interface EventNodeModelGenerics extends NodeModelGenerics {
	OPTIONS: EventNodeModelOptions;
}

export class EventNodeModel extends NodeModel<EventNodeModelGenerics> {
	protected portsIn: EventEndPortModel[];
    protected portsOut: EventEndPortModel[];
    eventId: string
    pathId: string

	constructor(name: string, color: string, eventId: string,  pathId: string);
	constructor(options?: EventNodeModelOptions);
	constructor(options: any = {}, color?: string, eventId?: string, pathId?: string) {
		if (typeof options === 'string') {
			options = {
				name: options,
				color: color
            };
		}
		super({
			type: 'event',
			name: options.name || 'Untitled',
			color: options.color || 'rgb(232, 255, 247)'
		});
		this.portsOut = [];
		this.portsIn = [];
        this.eventId = eventId!
        this.pathId = pathId!
	}

	doClone(lookupTable: {}, clone: any): void {
		clone.portsIn = [];
		clone.portsOut = [];
		super.doClone(lookupTable, clone);
	}

	removePort(port: EventEndPortModel): void {
		super.removePort(port);
		if (port.getOptions().in) {
			this.portsIn.splice(this.portsIn.indexOf(port), 1);
		} else {
			this.portsOut.splice(this.portsOut.indexOf(port), 1);
		}
	}

	addPort<T extends EventEndPortModel>(port: T): T {
		super.addPort(port);
		if (port.getOptions().in) {
			if (this.portsIn.indexOf(port) === -1) {
				this.portsIn.push(port);
			}
		} else {
			if (this.portsOut.indexOf(port) === -1) {
				this.portsOut.push(port);
			}
		}
		return port;
	}

	addInPort(label: string, after = true): EventEndPortModel {
		const p = new EventEndPortModel({
			in: true,
			name: label,
			label: label || '',
			alignment: PortModelAlignment.BOTTOM
		});
		if (!after) {
			this.portsIn.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	addOutPort(label: string, after = true): EventEndPortModel {
		const p = new EventEndPortModel({
			in: false,
			name: label,
			label: label ||'',
			alignment: PortModelAlignment.BOTTOM
		});
		if (!after) {
			this.portsOut.splice(0, 0, p);
		}
		return this.addPort(p);
	}

	deserialize(event: DeserializeEvent<this>) {
		super.deserialize(event);
		this.options.name = event.data.name;
		this.options.color = event.data.color;
		this.portsIn = event.data.portsInOrder.map(this.getPortFromID) as EventEndPortModel[]
		this.portsOut = event.data.portsOutOrder.map(this.getPortFromID) as EventEndPortModel[]
	}

	serialize(): any {
		return {
			...super.serialize(),
			name: this.options.name,
			color: this.options.color,
			portsInOrder: this.portsIn.map(port => port.getID()),
			portsOutOrder: this.portsOut.map(port => port.getID())
		};
	}

	getInPorts(): EventEndPortModel[] {
		return this.portsIn;
	}

	getOutPorts(): EventEndPortModel[] {
		return this.portsOut;
	}
}
