import React, { useCallback } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, TextField } from '@material-ui/core'

import { EventFormItem, Action } from '../../../../../../models/Event.model'

interface FormComponentProps {
    formItems: EventFormItem[]
    actionItem: Action
    finish: (filledData: EventFormItem[]) => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContainer: {
            marginTop: '10px'
        },
        formItems: {
            marginBottom: '10px'
        }
    })
)

const EventModalFormComponent = (props: FormComponentProps) => {
    const { formItems, finish, actionItem } = props
    const classes = useStyles()

    const onSubmit = useCallback(
        (e: React.FormEvent) => {
            e.preventDefault()

            formItems.forEach((item, index) => {
                console.log(item.key, ((e.target as any)[index] as any).value)
            })
            finish([])
        },
        [finish]
    )

    return (
        <form className={classes.formContainer} onSubmit={onSubmit}>
            <div className={classes.formItems}>
                {formItems.map((item, index) => (
                    <TextField
                        key={`item-${index}`}
                        label={item.key}
                        type={item.type}
                        required={item.required}
                        style={{ margin: 8, minWidth: ['text', 'email'].includes(item.type) ? 280 : 80 }}
                        multiline={item.isMultiLine}
                        // placeholder={item.key}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                ))}
            </div>

            <div className="actions-container">
                <Button size="large" className="action-button" type="submit">
                    {actionItem.label}
                </Button>
            </div>
        </form>
    )
}

export { EventModalFormComponent }
