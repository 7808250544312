import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'

import createEngine, { DiagramModel, DefaultDiagramState, LinkModel, LinkModelGenerics, DiagramEngine } from '@projectstorm/react-diagrams'

import { BaseEntityEvent, BaseModel, CanvasWidget } from '@projectstorm/react-canvas-core'

import dagre from 'dagre'

import Event, { DEFAULT_EVENT_END } from './../../../../../models/Event.model'

import { EventNodeFactory } from './graph-utils/event-node.factory'
import { EventNodeModel } from './graph-utils/event-node.model'
import { EventEndPortFactory } from './graph-utils/event-end-port/end-port.factory'
import { ActionsEnum } from '../../../../../utils/constants/action-types'
import { EventTypesEnum } from '../../../../../utils/constants/event-types'

import ZoomInIcon from '@material-ui/icons/ZoomInRounded'
import ZoomOutIcon from '@material-ui/icons/ZoomOutRounded'
import ZoomToFitIcon from '@material-ui/icons/ViewQuiltSharp'

import './graph.style.scss'
import { useStore } from '../../../../../stores/root.store'
import { debounce } from '@material-ui/core'

const GraphComponent: React.FC = observer(() => {
    const createStore = useStore('createStore')

    const [isLoading, setLoading] = useState(true)

    //1) setup the diagram engine
    const engine = useMemo(() => {
        const eng = createEngine()
        eng.getNodeFactories().registerFactory(new EventNodeFactory())
        eng.getPortFactories().registerFactory(new EventEndPortFactory())
        return eng
    }, [])

    //2) setup the diagram model
    const diagramModel = useMemo(() => new DiagramModel(), [])
    const eventNodes = useRef<{ [id: string]: EventNodeModel }>({}).current

    const clearGraph = useCallback(() => {
        diagramModel.getNodes().forEach((n) => {
            n.clearListeners()
            diagramModel.removeNode(n)
        })
        diagramModel.getLinks().forEach((l) => diagramModel.removeLink(l))
        Object.keys(eventNodes).forEach((key) => delete eventNodes[key])
    }, [diagramModel, eventNodes])

    const initGraph = useCallback(
        (shouldZoomToFit: boolean = true) => {
            const { paths } = createStore
            // ############################################ MAGIC HAPPENS HERE
            const state = engine.getStateMachine().getCurrentState()
            if (state instanceof DefaultDiagramState) {
                state.dragNewLink.config.allowLooseLinks = false
            }

            // In order to know the positions of the nodes, we use dagre
            // Create a new directed graph
            const g = new dagre.graphlib.Graph()

            // Set an object for the graph label
            g.setGraph({
                rankdir: 'TB', // Top-to-Bottom layout
                ranker:  'longest-path',
                ranksep: 100, // Minimum vertical distance between levels
                // marginy: 90
            })

            // Default to assigning a new object as a label for each new edge.
            g.setDefaultEdgeLabel(() => ({}))

            paths.forEach((path) => {
                path.events.forEach((e) => {
                    // Add nodes to the graph. The first argument is the node id. The second is
                    // metadata about the node. In this case we're going to add labels to each of
                    // our nodes.
                    g.setNode(e._id, { e, color: path.color, width: 180, height: 80, pathId: path._id })
                    e.dependencies.forEach((d) => {
                        e.dependencies.forEach((d) => {
                            if (d.availability.afterEvents && d.availability.afterEvents.length) {
                                d.availability.afterEvents.forEach((ae) => {
                                    // Add edges to the graph
                                    g.setEdge(e._id, ae.eventId, { eventEnd: ae.endWith.value })
                                })
                            }
                        })

                        d.availability.afterEvents?.forEach((ae) => g.setEdge(e._id, ae.eventId, { eventEnd: ae.endWith.value }))
                    })
                })
            })

            // Identify isolated nodes
            const connectedNodes = new Set(g.edges().flatMap((edge) => [edge.v, edge.w]))
            const isolatedNodes = g.nodes().filter((node) => !connectedNodes.has(node))

            // Assign isolated nodes the same rank as the root
            isolatedNodes.forEach((node) => g.setNode(node, { ...g.node(node), rank: 0 }))

            // Layout the graph
            dagre.layout(g)
            //3-C) link the 2 nodes together
            const links: LinkModel<LinkModelGenerics>[] = []
            const graphHeight = g.graph().height!

            // Build the ui of the graph
            g.nodes().forEach((v) => {
                const node = g.node(v)
                if (node) {
                    if (!eventNodes[v]) {
                        const event = (node as any).e as Event
                        let title = event.title
                        if (event.content.type === EventTypesEnum.Form) title += ' 📝'
                        eventNodes[v] = new EventNodeModel(title, (node as any).color, event._id, (node as any).pathId)
                    }
                    eventNodes[v].setPosition(node.x, graphHeight - node.y)
                }
            })

            // Add the links
            g.edges().forEach((e) => {
                const edge = g.edge(e)

                try {
                    const eventEnd = (edge as any).eventEnd
                    const dependsOn = (g.node(e.w) as any).e as Event
                    let actionText = ''

                    if (eventEnd === undefined || eventEnd.length === 0 || eventEnd === DEFAULT_EVENT_END) {
                        actionText = 'any'
                    } else {
                        if (dependsOn!.content.type === EventTypesEnum.WithActions) {
                            if (typeof eventEnd === 'number') {
                                actionText = dependsOn!.content!.actions!.filter((a) => a.type === ActionsEnum.EndWith)[eventEnd]?.label
                            } else {
                                actionText = dependsOn!.content!.actions!.find((c) => eventEnd === c.id)?.label || 'N/A'
                            }
                        } else if (dependsOn!.content.type === EventTypesEnum.Checkbox) {
                            actionText =
                                '☑️ ' +
                                dependsOn!
                                    .content!.checkboxes!.filter((c) => eventEnd.includes(c.id))
                                    .map((c) => c.label)
                                    .join(' ☑️ ')
                        }
                    }

                    const dependencyPort = eventNodes[e.v].addInPort('')
                    const eventEndPort = eventNodes[e.w].getPorts()[actionText] || eventNodes[e.w].addOutPort(actionText)

                    links.push(dependencyPort.link(eventEndPort))
                } catch (err) {
                    console.log(err)
                }

                if (shouldZoomToFit) {
                    setTimeout(() => {
                        zoomToFit()
                    }, 500)
                }
            })

            //4) add the models to the root graph
            // model.addAll(node1, node2, link1)
            const models = diagramModel.addAll(...Object.values(eventNodes), ...links)
            // diagramModel.addAll(...Object.values(eventNodes), ...links)

            diagramModel.setLocked(true)

            //5) load model into engine
            engine.setModel(diagramModel)

            setLoading(false)

            // add a selection listener to each
            models.forEach((item) => {
                const oldListener = item.getListenerHandle({ selectionChanged: onSelectionChanged })

                if (oldListener) {
                    oldListener.deregister()
                }

                item.registerListener({
                    selectionChanged: onSelectionChanged
                })
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createStore, diagramModel, engine, eventNodes]
    )

    const expandAccordion = (id: string, attribute: 'event-id' | 'path-id') => {
        const element = document.querySelector(`[${attribute}="${id}"]`) as HTMLElement | null
        if (element) {
            // Ensure it's expanded
            const accordionButton = element.querySelector('.MuiAccordionSummary-root') // Adjust selector if needed
            if (accordionButton && !element.classList.contains('panelExpanded')) {
                ;(accordionButton as HTMLElement).click() // Click to expand
            }
        }
    }

    const togglePortLinksSelection = (ports: any[], isSelected: boolean) => {
        ports.forEach((port: any) => {
            Object.values(port.links).forEach((link: any) => {
                if ('setSelected' in link) {
                    link.setSelected(isSelected)
                }
            })
        })
    }

    const onSelectionChanged = (event: any) => {
        expandAccordion(event.entity.eventId, 'event-id')
        expandAccordion(event.entity.pathId, 'path-id')

        const element = document.querySelector(`[event-id="${event.entity.eventId}"]`) as HTMLElement | null
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }, 10)
        }

        if (event.entity.portsIn?.length) {
            togglePortLinksSelection(event.entity.portsIn, event.isSelected)
        }

        if (event.entity.portsOut?.length) {
            togglePortLinksSelection(event.entity.portsOut, event.isSelected)
        }
    }

    useEffect(() => {
        const debouncedHandlePropsChange = debounce((fullPath: string) => {
            clearGraph()
            initGraph(false)

            // Perform any necessary updates
            const match = fullPath.match(/^(paths\/\d+\/events\/\d+)/)
            if (match) {
                const event = createStore.getPropertyByPath(match[1]) as Event
                const node = eventNodes[event._id]

                engine.getModel().clearSelection()
                node.setSelected(true)
                // const node = engine.getModel().getNode(event._id)

                engine.zoomToFitNodes({ margin: 100, nodes: [node], maxZoom: 1 })
                engine.zoomToFitSelectedNodes({ margin: 100, maxZoom: 0.8 })
            }
        }, 1000)

        createStore.listenToPropChange(debouncedHandlePropsChange)

        return () => {
            createStore.stopListenToPropChange(debouncedHandlePropsChange)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearGraph, createStore, initGraph])

    useEffect(() => {
        clearGraph()
        initGraph()
    }, [clearGraph, initGraph])

    const zoomToFit = useCallback(() => engine.zoomToFit(), [engine])

    const zoomIn = useCallback(() => {
        const zoom = diagramModel.getZoomLevel()
        diagramModel.setZoomLevel(zoom + 5)
        engine.repaintCanvas()
    }, [diagramModel, engine])

    const zoomOut = useCallback(() => {
        const zoom = diagramModel.getZoomLevel()
        diagramModel.setZoomLevel(zoom - 5)
        engine.repaintCanvas()
    }, [diagramModel, engine])

    const [styles, setStyles] = useState<React.CSSProperties>({ top: 72, bottom: 0 })
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY
            const windowHeight = window.innerHeight
            const bodyHeight = document.body.offsetHeight

            if (scrollY > 130 && scrollY + windowHeight < bodyHeight - 130) {
                setStyles({ top: 0, bottom: 'auto' })
            } else if (scrollY + windowHeight >= bodyHeight - 100) {
                setStyles({ top: 'auto', bottom: 100 })
            } else {
                setStyles({ top: 130, bottom: 0 })
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    if (!engine.getModel()) {
        // For hot refreshing
        return <>Loading</>
    }
    return (
        <div className="graph-container" style={styles}>
            <div className="buttons">
                <div onClick={zoomToFit}>
                    <ZoomToFitIcon />
                </div>
                <div onClick={zoomIn}>
                    <ZoomInIcon />
                </div>
                <div onClick={zoomOut}>
                    <ZoomOutIcon />
                </div>
            </div>

            {isLoading ? <h2>Loading...</h2> : <CanvasWidget engine={engine} className="graph-container" />}
        </div>
    )
})

export default GraphComponent
