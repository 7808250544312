import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import PathEvent, { Availability } from '../../../../../models/Event.model'
import { availabilityOptions, AvailabilitiesEnum } from '../../../../../utils/constants/availability-types'
import TimeSelectorComponent from '../../../../../utils/components/time-selector'
import EventSelectorComponent from '../../../../../utils/components/event-selector';

interface AvailabilityComponentProps {
    availability: Availability,
    fullPath: string
}

@observer
class AvailabilityComponent extends Component<AvailabilityComponentProps, object> {
  @observable availabilityIdx: number = AvailabilitiesEnum.AfterTime
  private fullPath: string
  hasAvailabilityIdxChanged = false

  constructor(props: AvailabilityComponentProps) {
    super(props)
    const {fullPath, availability} = props

    this.fullPath = `${fullPath}/availability`

    if (availability.afterEvents !== undefined && availability.afterEvents!.length) {
      this.availabilityIdx = AvailabilitiesEnum.AfterEvents
    }
  }

  @action onAvailabilitySelected = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.availabilityIdx = parseInt(event.target.value)
    this.hasAvailabilityIdxChanged = true

    if (this.availabilityIdx === AvailabilitiesEnum.AfterTime) {
      this.props.availability.afterEvents = undefined
    }
  }

  // Added this to support dragging
  componentWillUpdate(props: AvailabilityComponentProps) {
    if (!this.hasAvailabilityIdxChanged) {
      if (props.availability.afterEvents !== undefined) {
        this.availabilityIdx = AvailabilitiesEnum.AfterEvents
      } else {
        this.availabilityIdx = AvailabilitiesEnum.AfterTime
      }
    }
    this.hasAvailabilityIdxChanged = false
  }

  render() {
    const { availability } = this.props
      
    return (
      <div className={'event-box-border'}>
          <label className={'event-box-label'}>Availability</label>
            <TextField
              id="availability-option"
              select
              label="Available"
              SelectProps={{
              }}
              margin="normal"
              value={this.availabilityIdx}
              onChange={this.onAvailabilitySelected}
            >
            {availabilityOptions.map((option: any, idx: number) => (
              <MenuItem key={`o${idx}`} value={idx}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
            <AfterView availability={availability} fullPath={this.fullPath} availabilityIdx={this.availabilityIdx}/>
        </div>
    )
  }
}

const AfterView = observer((props: {availabilityIdx: number, availability: Availability, fullPath: string}) => {
  const { availabilityIdx, fullPath } = props
  switch (availabilityIdx) {
    case AvailabilitiesEnum.AfterTime:
      const timePath = `${fullPath}/afterTime`
      return <TimeSelectorComponent timePath={timePath} shouldShowFrom/>
    case AvailabilitiesEnum.AfterEvents:
      const eventFullPath = `${fullPath}/afterEvents`
      return <EventSelectorComponent avoidCurrentEvent eventFullPath={eventFullPath}/>
  }

  return <span></span>
})

export default AvailabilityComponent
