import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import LaunchIcon from '@material-ui/icons/PlayArrow'
import EditIcon from '@material-ui/icons/EditOutlined'
import UsersIcon from '@material-ui/icons/PersonOutline'

import './organization-card.style.scss'
import OrganizationStore from '../../../../stores/organization.store'

import { WithTranslation, withTranslation } from 'react-i18next'

interface OrganizationCardProps extends WithTranslation {
  organizationStore?: OrganizationStore
}

@inject((stores) => ({
  organizationStore: (stores as any).organizationStore as OrganizationStore
}))
@observer
class OrganizationCard extends Component<OrganizationCardProps, object> {


  constructor(props: any) {
    super(props)
  }

  componentDidMount = () => {
    // this.props.mainStore.init()
  }

  componentWillUnmount = () => {
    // this.props.mainStore.reset()
  }

  startEditing = () => {
    this.props.organizationStore!.startEditOrganization(this.props.organizationStore!.organization!)
  }

  render() {
    const { t } = this.props
    const { organization } = this.props.organizationStore!

    if (!organization) {
      return <span></span>
    }

    return (
      <Card className='organization-card'>
        {/* <CardHeader></CardHeader> */}
        <CardContent className='content'>
          <span className='row'>
            {!!organization.logo && <img src={organization.logo} />}
            <Typography variant='h5'> {organization.name}</Typography>
          </span>
          <span>

          </span>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Button onClick={this.startEditing}>{t('edit')}<EditIcon style={{ margin: '0 4px '}}/></Button>
            <Button onClick={() => this.props.organizationStore!.setIsEditingUsers(true)}>{t('manageUsers')}<UsersIcon style={{ margin: '0 4px '}} /></Button>
            {!!organization && organization._id && <a href={`https://simplan.app/organization/${organization._id}`} target='_blank' title='Launch'>
              <Button>{t('openInApp')}<LaunchIcon style={{ margin: '0 4px '}}/></Button>
            </a>}
          </div>

        </CardContent>

        {/* <CardActions></CardActions> */}
      </Card>
    )
  }
}


export default withTranslation('organizationCard')(OrganizationCard)
