import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable, action } from 'mobx'
import { withTranslation, WithTranslation } from 'react-i18next'

import { DragDropContext, DropResult, ResponderProvided, Droppable } from 'react-beautiful-dnd'

// import DevTools from 'mobx-react-devtools'
import CreateStore from '../../../stores/create.store'
import MainStore from '../../../stores/main.store'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'

import { CircularProgress, Fab, IconButton, Tooltip } from '@material-ui/core'
import LaunchIcon from '@material-ui/icons/PlayArrow'
import CloneIcon from '@material-ui/icons/FileCopyRounded'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockedIcon from '@material-ui/icons/Lock'
import ShowChartIcon from '@material-ui/icons/ShowChart'

import { RouteComponentProps } from 'react-router'

import PathComponent from './components/path/path.component'
import Path from './../../../models/Path.model'
import PathEvent from './../../../models/Event.model'

import SettingsStore from '../../../stores/settings.store'
import { PlanTypeOptions, PlanTypeEnum } from '../../../utils/constants/plan-type'
import Organization from '../../../models/settings/Organization.model'
import GraphComponent from './components/graph/graph.component'
import PermissionsDialogComponent from './components/permissions-modal/Permission-modal.component'

import EventModalComponent from './components/event/displayed-event/event-modal.component'
import AuthStore from '../../../stores/auth.store'
import './CreateScreen.style.scss'
import { PlansColors } from './components/PlanColors.component'
import { DeadlinePicker } from './components/Deadline'
import { PlanRolesPicker } from './components/PlanRolesPicker'
import { RequestedCustomerDetails } from './components/customer-details/plan-customer-details'
import DeletePathModal from './DeletePathModal.component'

const ids = new Set(['6ZMImFWGdrfbtR4cqjQpgvCNkP83', 'B4bjYG3oXBTjlAszBPxsTX1LLFt2', 'OihjxWbRuPahtC1S2Q2VMJxlfMy2'])

const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? 'lightblue' : 'white'
})

interface CreateScreenProps extends RouteComponentProps, Partial<WithTranslation> {
    createStore?: CreateStore
    mainStore?: MainStore
    settingsStore?: SettingsStore
    authStore?: AuthStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
    mainStore: (stores as any).mainStore as MainStore,
    settingsStore: (stores as any).settingsStore as SettingsStore,
    authStore: (stores as any).authStore as AuthStore
}))
@observer
class CreateScreen extends Component<CreateScreenProps, object> {
    @observable organizationIdx: number = 0
    @observable isGraphShown: boolean = true

    @observable candidateDeletePath?: Path
    @observable candidateDeletePathIdx: number = -1
    @observable dependedEvents: PathEvent[] = []

    hasAvailabilityIdxChanged = false

    constructor(props: CreateScreenProps) {
        super(props)
    }

    async componentWillMount() {
        const { match, createStore, settingsStore } = this.props

        const { organizations } = settingsStore!
        const { id } = match.params as any

        if (id) {
            await createStore!.setPlan(id)

            if (createStore!.organizationId) {
                this.organizationIdx = organizations.findIndex((o: Organization) => o._id === createStore!.organizationId) + 1
            }
        } else {
            createStore!.startCreating()
        }
    }

    componentWillUnmount = () => {
        this.props.createStore!.reset()
    }

    onSubmit = async (a: React.FormEvent) => {
        const { createStore, mainStore } = this.props
        a.preventDefault()
        try {
            await createStore!.submit()
            mainStore!.reset()
            mainStore!.init()
            // history.push('/')
        } catch (err) {
            console.error('Oy vey', err)
        }
    }

    setTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.createStore!.setTitle(undefined, event.target.value)
    }

    setVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.createStore!.setVersion(parseInt(event.target.value, 10))
    }

    setDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.createStore!.setDescription(event.target.value)
    }

    setPixelId = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.createStore!.setPixelId(event.target.value)
    }

    onDevelopmentChanged = (newState: boolean) => {
        this.props.createStore!.setInDevelopment(newState)
    }

    onPlanTypeChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.createStore!.setPlanType(parseInt(event.target.value))
    }

    onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
        if (!result.destination) {
            return
        }

        this.props.createStore!.reorderPaths(result.source.index, result.destination.index)
    }

    @action onRemovePathPressed = (idx: number, path: Path) => {
        path.events.forEach((event) => {
            this.dependedEvents.push(...this.props.createStore!.getDependedEvents(event))
        })
        this.candidateDeletePath = path
        this.candidateDeletePathIdx = idx
    }

    onConfirm = () => {
        this.props.createStore!.removePath(this.candidateDeletePathIdx)
        this.onCanceled()
    }

    @action onCanceled = () => {
        this.candidateDeletePath = undefined
        this.candidateDeletePathIdx = -1
        this.dependedEvents = []
    }

    @action onOrganizationSelected = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.organizationIdx = parseInt(event.target.value)

        if (this.organizationIdx > 0) {
            const { organizations } = this.props.settingsStore!

            this.props.createStore!.organizationId = organizations[this.organizationIdx - 1]._id
        } else {
            this.props.createStore!.organizationId = ''
        }
    }

    @action toggleGraph = () => {
        this.isGraphShown = !this.isGraphShown
    }

    togglePermissionsModal = () => {
        this.props.createStore!.togglePermissionsModal()
    }

    showAnalytics = () => {
        const { plan } = this.props.createStore!
        if (plan) {
            window.open(`/analytics/${plan._id}`, '_blank')
        }
    }

    render() {
        const { createStore, authStore, t } = this.props
        const {
            inDevelopment,
            isLoading,
            plan,
            title,
            planType,
            version,
            paths,
            addPath,
            addEvent,
            shouldShowOrganizationPicker,
            pixelId,
            description,
            isEditMode,
            removePath,
            duplicatePlan,
            isLocked
        } = createStore!

        const { organizations } = this.props.settingsStore!

        let lengthTypeIdx = 3

        if (isLoading) {
            return (
                <div className="loading">
                    <CircularProgress />
                </div>
            )
        }

        const deletePathModal = {
            path: this.candidateDeletePath,
            dependedEvents: this.dependedEvents,
            toggleModal: this.onCanceled,
            deletePath: this.onConfirm,
            t: t
        }

        return (
            <div className="create-container">
                <PermissionsDialogComponent />
                <EventModalComponent />

                {!!this.candidateDeletePath && <DeletePathModal {...deletePathModal} />}

                <Card className="main-card">
                    <form onSubmit={this.onSubmit}>
                        <CardContent className="card-content">
                            <div className="header">
                                <Typography variant="h6">{isEditMode ? `Edit ${title} plan` : 'Create a plan'}</Typography>

                                <div className="left-side">
                                    {planType === PlanTypeEnum.Sales && !!plan && (
                                        <Tooltip title={t!('showAnalytics') as string}>
                                            <IconButton color="primary" component="span" onClick={this.showAnalytics}>
                                                <ShowChartIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Tooltip title={t!('managePermissions') as string}>
                                        <IconButton color="primary" component="span" onClick={this.togglePermissionsModal}>
                                            {isLocked ? <LockedIcon /> : <LockOpenIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="content">
                                <Card className="form-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <div className="row">
                                            <TextField
                                                id="path_title"
                                                label="Title"
                                                required
                                                style={{ margin: 8, minWidth: 220 }}
                                                placeholder="title"
                                                value={title}
                                                margin="normal"
                                                onChange={this.setTitle}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />

                                            <TextField
                                                id="Plan-type"
                                                select
                                                label="Plan Type"
                                                SelectProps={{}}
                                                margin="normal"
                                                style={{ minWidth: 85, margin: 8 }}
                                                value={planType}
                                                onChange={this.onPlanTypeChanged}
                                            >
                                                {PlanTypeOptions.map((option: any, idx: number) => (
                                                    <MenuItem key={`t${idx}`} value={idx}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            {planType === PlanTypeEnum.Sales && (
                                                <TextField
                                                    id="path_version"
                                                    label="Version"
                                                    type="number"
                                                    required
                                                    style={{ margin: 8, minWidth: 40 }}
                                                    placeholder="Version"
                                                    value={version}
                                                    margin="normal"
                                                    onChange={this.setVersion}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {planType === PlanTypeEnum.MultiUsers && <PlanRolesPicker />}

                                        {planType === PlanTypeEnum.Sales && <PlansColors createStore={createStore!} />}

                                        {planType === PlanTypeEnum.Sales && (
                                            <TextField
                                                id="pixelId"
                                                label={t!('pixelId')}
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                value={pixelId}
                                                onChange={this.setPixelId}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        )}

                                        <div style={{ marginTop: '8px' }}>
                                            <RequestedCustomerDetails />
                                        </div>

                                        <div style={{ padding: '0 8px' }}>
                                            <DeadlinePicker
                                                getTime={createStore!.getDeadlineTime}
                                                getType={createStore!.getDeadlineType}
                                                onTimeChanged={createStore!.setDeadline}
                                            />
                                        </div>

                                        <TextField
                                            id="description"
                                            label={t!('description')}
                                            multiline
                                            variant="outlined"
                                            fullWidth
                                            minRows="3"
                                            margin="normal"
                                            value={description}
                                            onChange={this.setDescription}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />

                                        {shouldShowOrganizationPicker && (
                                            <div className="row">
                                                <TextField
                                                    id="availability-option"
                                                    select
                                                    fullWidth
                                                    label="Organization"
                                                    SelectProps={{}}
                                                    margin="normal"
                                                    value={this.organizationIdx}
                                                    onChange={this.onOrganizationSelected}
                                                >
                                                    <MenuItem key={`o0`} value={0}>
                                                        {'None'}
                                                    </MenuItem>

                                                    {organizations.map((option: any, idx: number) => (
                                                        <MenuItem key={`o${idx}`} value={idx + 1}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                                {/* {this.organizationIdx >= 0 && <FormControlLabel
                        control={
                          <Checkbox checked={displayOnOrganizationOnly}
                            onChange={(e, checked) => this.onDisplayWithOrganizationOnlyChanged(checked)} />
                        }
                        label={'Display in organization only?'} />} */}
                                            </div>
                                        )}

                                        {/* No more in development. only for cancel */}
                                        {inDevelopment && (
                                            <div className={'event-box-border'}>
                                                <label className={'event-box-label'}>Plan Settings</label>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={inDevelopment}
                                                            onChange={(e, checked) => this.onDevelopmentChanged(checked)}
                                                        />
                                                    }
                                                    label={t!('inDevelopment')}
                                                />
                                            </div>
                                        )}
                                        <Droppable droppableId={'pathsDroppable'}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    <PathList paths={paths} addEvent={addEvent} removePath={this.onRemovePathPressed} />
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Card className={'add-card'}>
                                        <Button fullWidth onClick={addPath}>
                                            Add Path
                                        </Button>
                                    </Card>
                                </Card>

                                <Card className="graph-container-card">
                                    {/* <FormControlLabel
                                        control={<Checkbox checked={this.isGraphShown} onChange={(e, checked) => this.toggleGraph()} />}
                                        label={'Show graph. (Hide for a better performance)'}
                                    /> */}
                                    {this.isGraphShown && <GraphComponent />}
                                </Card>
                            </div>
                            {/* <DevTools /> */}
                        </CardContent>
                        <CardActions>
                            <Button type="submit" fullWidth>
                                {isEditMode ? 'Save Changes' : 'Save'}
                            </Button>

                            {planType !== PlanTypeEnum.Template && !!plan && plan._id && (
                                <a href={`https://simplan.app/plans/${plan._id}`} target="_blank" title="Launch" rel="noreferrer">
                                    <Fab color="primary" aria-label="Launch" style={{ right: '10px', position: 'absolute' }}>
                                        <LaunchIcon />
                                    </Fab>
                                </a>
                            )}

                            {!!authStore!.loggedUser && ids.has(authStore!.loggedUser.id) && (
                                <Fab
                                    color="primary"
                                    aria-label="Duplicate"
                                    style={{ left: '10px', position: 'absolute' }}
                                    onClick={() => duplicatePlan()}
                                >
                                    <CloneIcon />
                                </Fab>
                            )}
                        </CardActions>
                    </form>
                </Card>
            </div>
        )
    }
}

interface PathListProps {
    paths: Path[]
    removePath: (idx: number, path: Path) => void
    addEvent: (event: any) => void
}
const PathList = observer((props: PathListProps) => {
    const { paths, removePath, addEvent } = props
    return (
        <div>
            {paths.map((path: Path, idx: number) => (
                <PathComponent
                    path={path}
                    removePath={removePath.bind({}, idx, path)}
                    addEvent={addEvent.bind({}, idx)}
                    key={`p-${idx}`}
                    dataKey={idx}
                />
            ))}
        </div>
    )
})

// const DragDropContextMobx = observer((props: DragDropContextProps & {children : ReactElement[] }) => {
//   return (<DragDropContext {...props}>
//       {props.children}
//     </DragDropContext>)
// })

export default withTranslation('createScreen')(CreateScreen as any)
