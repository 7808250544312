import axios from 'axios'

import Plan from '../../models/Plan.model'
import Organization from '../../models/settings/Organization.model'

class ClientService {
    async updatePlan(plan: Plan): Promise<void> {
        const { data } = await axios.post(`/plans/${plan._id}`, plan)
        return data
    }

    async deletePlan(planId: string): Promise<void> {
        const { data } = await axios.delete(`/plans/${planId}`)
        return data
    }
}

export default new ClientService()
